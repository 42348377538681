import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "backoffice"
    }}>{`Backoffice`}</h1>
    <p>{`Backoffice is a VPN protected service providing a SOAP interface to NOD (Nasjonal Ordredatabase).
This service provides interfaces for creating and modifying travel cards and tickets. These commands
include, but are not limited to, `}<em parentName="p">{`selling tickets`}</em>{`, `}<em parentName="p">{`activating/deactivating tickets`}</em>{` or `}<em parentName="p">{`cancelling
a purchased ticket`}</em>{`.`}</p>
    <h2 {...{
      "id": "nod-and-backoffice-documentation---as-of-2019-11-11"
    }}>{`NOD and Backoffice documentation - as of (2019-11-11)`}</h2>
    <p>{`The documentation for NOD and the Backoffice interface is distributed thorugh Slack in
`}<em parentName="p">{`#releases-nod-pl4`}</em>{` and are `}<strong parentName="p">{`NOT`}</strong>{` stand alone. The `}<inlineCode parentName="p">{`.zip`}</inlineCode>{`-files accessible in that channel contain
a set of documenting `}<inlineCode parentName="p">{`.docx`}</inlineCode>{`-files and `}<inlineCode parentName="p">{`.wsdl`}</inlineCode>{`s and `}<inlineCode parentName="p">{`.xsd`}</inlineCode>{`s that can be used to generate SOAP
clients. Exploratory missions are expected when trying to find what you are looking for.`}</p>
    <p>{`This application contains a somewhat complete set of `}<inlineCode parentName="p">{`.wsdl`}</inlineCode>{` and `}<inlineCode parentName="p">{`.xsd`}</inlineCode>{` files.`}</p>
    <h3 {...{
      "id": "orderdescription"
    }}>{`OrderDescription`}</h3>
    <p>{`In the distributed `}<inlineCode parentName="p">{`.zip`}</inlineCode>{`-files from NOD there are no descriptions of the class `}<inlineCode parentName="p">{`OrderDescription`}</inlineCode>{`.
Therefore these are implemented manually in the package
`}<inlineCode parentName="p">{`no.ioas.no.backoffice.orderdomain.orderdescription`}</inlineCode>{`. The structure of the data classes are
guesstimated from the scenarios found in the mentioned documentation.`}</p>
    <p>{`To add new `}<inlineCode parentName="p">{`WSORderService`}</inlineCode>{` actions (like `}<em parentName="p">{`cancelProductActivation`}</em>{` and `}<em parentName="p">{`validateProduct`}</em>{`) look in
the scenarios in NOD-PTO-x.x.x.zip. This `}<inlineCode parentName="p">{`.zip`}</inlineCode>{` file is found in the mentioned Slack channel.`}</p>
    <h3 {...{
      "id": "additional-documentation-can-be-found-at"
    }}>{`Additional documentation can be found at:`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://enturas.atlassian.net/wiki/spaces/TVM2018/pages/880771500/Dokumentasjon+NOD"
        }}>{`https://enturas.atlassian.net/wiki/spaces/TVM2018/pages/880771500/Dokumentasjon+NOD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://enturas.atlassian.net/wiki/spaces/BNOP/overview"
        }}>{`https://enturas.atlassian.net/wiki/spaces/BNOP/overview`}</a></li>
    </ul>
    <h3 {...{
      "id": "relevant-slack-channels"
    }}>{`Relevant Slack channels`}</h3>
    <ul>
      <li parentName="ul">{`#`}{`releases-nod-pl4`}</li>
      <li parentName="ul">{`#`}{`meet-tickets-nod-pl4`}</li>
    </ul>
    <h2 {...{
      "id": "important-notes"
    }}>{`Important notes`}</h2>
    <ul>
      <li parentName="ul">{`Backoffice in `}<em parentName="li">{`production`}</em>{` and `}<em parentName="li">{`QA`}</em>{` is protected behind a VPN, and their `}<em parentName="li">{`test`}</em>{` environment is
accessible on the open Internet. This means that `}<em parentName="li">{`test`}</em>{` is accessible on `}<inlineCode parentName="li">{`https`}</inlineCode>{` and mapped with a
domain name, while `}<em parentName="li">{`production`}</em>{` and `}<em parentName="li">{`QA`}</em>{` is only available on `}<inlineCode parentName="li">{`http`}</inlineCode>{` and mapped with an
IP-address.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      